import * as React from "react"
import { graphql } from "gatsby"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import Moment from "moment"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const ProductPage = ({ data }) => {
  const posts = data.posts.nodes
  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div>
      <Seo
        title="News"
        description="Recent news from the Home of Industrial Imagination 💭"
      />
      {/* Blog Posts */}
      <section className="inline-block bg-white dark:bg-zinc-800 mb-0">
        <h1 className="sm:text-3xl text-2xl font-bold mb-0 text-center text-zinc-900 dark:text-zinc-200 mt-6">
          Recent News
        </h1>
        <p className="text-xs text-center font-light tracking-widest text-zinc-700 dark:text-zinc-400 mb-4">
          FROM THE BUDMEN WORKSHOP
        </p>
        <div className="w-5/6 mx-auto gap-4 grid grid-cols-1 md:grid-cols-2">
          {posts.map(
            (posts, index) =>
              posts.slug !== null && (
                <AniLink
                  to={"/news/" + posts.slug.current}
                  paintDrip
                  hex={
                    "#" + posts.category.color !== null
                      ? posts.category.color
                      : "15b8a6"
                  }
                  duration={0.5}
                  className="text-zinc-900 dark:text-zinc-300"
                  key={index}
                >
                  <div className="inline-block bg-white dark:bg-zinc-700 mb-4 shadow-lg border dark:border-zinc-800">
                    {posts.mainImage !== "undefined" ? (
                      <img
                        src={urlFor(posts.mainImage.asset.url)
                          .width(1200)
                          .height(600)
                          .auto("format")
                          .url()}
                        alt={posts.title}
                        className="p-2 mb-0"
                      />
                    ) : (
                      <img
                        src="/placeholder.png"
                        alt={posts.title}
                        className="p-2 mb-0"
                      />
                    )}
                    <div className="p-2">
                      <div className="flex justify-between text-[0.6rem] mb-2">
                        <span>{Moment(posts.date).format("MMMM D, YYYY")}</span>
                        <span
                          style={{ background: posts.category.color }}
                          className="rounded-full  text-white px-2"
                        >
                          {posts.category.title}
                        </span>
                      </div>
                      <div className="flex relative items-center">
                        <span className="flex-shrink mr-1 font-bold text-zinc-500 tracking-widest text-[0.5rem]">
                          TITLE
                        </span>
                        <div className="flex-grow border-t dark:border-zinc-500"></div>
                      </div>
                      <h3 className="font-bold 	text-sm">{posts.title}</h3>
                      <div className="flex relative items-center mt-2">
                        <span className="flex-shrink mr-1 font-bold text-zinc-500 tracking-widest text-[0.5rem]">
                          EXCERPT
                        </span>
                        <div className="flex-grow border-t dark:border-zinc-500"></div>
                      </div>
                      <p className="text-sm">{posts.excerpt}</p>
                    </div>
                  </div>
                </AniLink>
              )
          )}
        </div>
      </section>
    </div>
  )
}

export default ProductPage

export const query = graphql`
  query newsPage {
    posts: allSanityPost(
      sort: { fields: [date], order: DESC }
      filter: { _id: { glob: "!drafts*" } }
    ) {
      nodes {
        date
        _createdAt
        mainImage {
          asset {
            url
          }
        }
        title
        category {
          color
          title
        }
        slug {
          current
        }
        excerpt
      }
    }
  }
`
